import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';

import { SetupInterface } from '@common/interfaces/setup.interface';
import { PageType, RouteString } from '@common/enums';

import { map, take, tap } from 'rxjs/operators';

import { StoreService } from '../services/store.service';
import { AppNavService } from '../services/app-nav.service';


// GuardResolver means that it behaves like Guard, but implemented as Resolver
// by the reason of currently implemented DataFlow
// and the fact that in case it will be Guard – main resolver for Setup won't be called
export const flowPageGuardResolver: ResolveFn<unknown> = (
  route: ActivatedRouteSnapshot,
  _: RouterStateSnapshot,
) => {
  return flowPageGuardFn(route);
};

export const flowPageGuardFn = (
  route: ActivatedRouteSnapshot,
  storeService = inject(StoreService),
  appNavService = inject(AppNavService),
) => {
  return storeService
    .setup$
    .pipe(
      take(1),
      tap((setup) => {
        if (!pageInSequence(setup, route.routeConfig.path as RouteString)) {
          appNavService.goToNotFound();
        }
      }),
      map(() => {
        return undefined;
      }),
    );
};

export function pageInSequence(setup: SetupInterface, path: RouteString): boolean {
  switch (path) {
    case RouteString.ANSWERING:
      return setup.sequence.includes(PageType.Answering);
    case RouteString.DONE:
      return setup.sequence.includes(PageType.SimpleDone);
    case RouteString.DOB_AUTH:
      return setup.sequence.includes(PageType.DOBAuthentication);
    case RouteString.POSTCODE_AUTH:
      return setup.sequence.includes(PageType.DOBAuthentication);
    default:
      return false;
  }
}
