import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { RouteString } from '../libs/common/enums/route.string';

import { NotFoundComponent } from './components/not-found/not-found.component';
import { ShellComponent } from './shell/components/shell/shell.component';
import { HomeComponent } from './components/home/home.component';
import { SetupResolver } from './core/resolvers/setup.resolver';
import { AnsweringComponent } from './pages/answering';
import { ErrorComponent } from './pages/error';
import { ExpiredComponent } from './pages/expired';
import { BirthdayComponent } from './pages/birthday/birthday/birthday.component';
import { RedirectToFirstInSequenceComponent } from './components/redirect-to-first-in-sequence/redirect-to-first-in-sequence.component';
import { PostcodeAuthComponent } from './pages/postcode-auth';
import { DoneRouteComponent } from './pages/done/done-route/done-route.component';
import {
  bdayVerificationPageGuardResolver,
  postcodeVerificationPageGuardResolver,
  verificationGuardResolver,
  flowPageGuardResolver,
} from './core/resolvers';


const routes: Routes = [
  { path: `${RouteString.NOT_FOUND}`, component: NotFoundComponent },
  { path: '', component: HomeComponent },
  { path: `${RouteString.EXPIRED}`, component: ExpiredComponent },
  { path: `${RouteString.ERROR}`, component: ErrorComponent },
  {
    path: 'r/:token',
    component: ShellComponent,
    resolve: { setup: SetupResolver },
    runGuardsAndResolvers: (curr: ActivatedRouteSnapshot, future: ActivatedRouteSnapshot) => {
      return future.firstChild?.routeConfig.path === RouteString.ANSWERING;
    },
    children: [
      {
        path: `${RouteString.DONE}`,
        component: DoneRouteComponent,
        resolve: {
          isInSequence: flowPageGuardResolver,
          dob: verificationGuardResolver,
        },
      },
      {
        path: `${RouteString.ANSWERING}`,
        component: AnsweringComponent,
        resolve: {
          isInSequence: flowPageGuardResolver,
          dob: verificationGuardResolver,
        },
      },
      {
        path: `${RouteString.DOB_AUTH}`,
        component: BirthdayComponent,
        resolve: [
          bdayVerificationPageGuardResolver,
        ],
      },
      {
        path: `${RouteString.POSTCODE_AUTH}`,
        component: PostcodeAuthComponent,
        resolve: [
          postcodeVerificationPageGuardResolver,
        ],
      },
      {
        path: '',
        pathMatch: 'full',
        component: RedirectToFirstInSequenceComponent,
      },
      {
        path: '**',
        component: RedirectToFirstInSequenceComponent,
      },
    ],
  },
  { path: '**', component: HomeComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { enableTracing: false }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
