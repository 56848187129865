{
  "labelTexts": {
    "firstName": "Vorname",
    "lastName": "Nachname",
    "nameSuffix": "Namenszusatz",
    "birthday": "Geburtstag",
    "email": "E-Mail",
    "phone": "Telefonnummer",
    "address": "Adresse",
    "house": "Hausnummer",
    "addition": "Ortsteil",
    "country": "Land",
    "city": "Stadt",
    "postcode": "Postleitzahl",
    "street": "Straße",
    "extra3": {
      "landline": "Empfängerangabe",
      "mobile": "Adresszusatz"
    },
    "org": "Organisation",
    "companyName": "Unternehmen",
    "jobTitle": "Position",
    "link": "Link",
    "mobile": "Handynummer",
    "fax": "Faxnummer",
    "home": "Zu Hause",
    "personal": "Privat",
    "work": "Geschäftliche",
    "position-alt": "Position",
    "salutation-alt": "Anrede",
    "title": "Titel",
    "website-alt": "Webseite",
    "company-alt": "Unternehmen",
    "readOnly": "Dieses Feld kann nicht bearbeitet werden.",
    "required": "Diese Information ist erforderlich."
  },
  "sender": {
    "buttons": {
      "reply": "Meine Infos teilen",
      "hideDetails": "Mehr Informationen",
      "showDetails": "Weniger Informationen"
    },
    "defaultMessage": "Hallo! Hier schicke ich dir meine Kontaktdaten. Würdest du mir auch deine freigeben? Es freut mich, wenn wir in Kontakt bleiben können!",
    "defaultMessage-alt": "Vielen Dank für die Aktualisierung Ihrer Daten. Bei Fragen sind wir gerne für Sie da.",
    "vcardInfo": "Am Ende kannst du dir alle Infos als vCard herunterladen.",
    "vcardInfo-alt": "Am Ende können Sie sich alle Infos als vCard herunterladen.",
    "offersYou": "bietet Ihnen diesen Service im Auftrag der",
    "imprint": "Zum Impressum der",
    "privacy": "Zum Datenschutz der",
    "link": "",
    "anchor": "",
    "phoneNumber": "+49 2692-2689886"
  },
  "complero": {
    "imprint": "Im folgenden finden Sie das Impressum der Complero GmbH.",
    "privacy": "Im folgenden finden Sie die Datenschutzerklärung der Complero GmbH."
  },
  "receiver": {
    "messages": {
      "doubleCheck": "Sicher, dass alle Angaben richtig sind?",
      "doubleCheck-alt": "Wir konnten Ihre letzte Rechnung nicht zustellen. Bitte aktualisieren Sie Ihre Anschrift."
    },
    "avatar": {
      "hint": "Klicken zum Hochladen",
      "choosePhoto": "Foto auswählen",
      "ok": "OK",
      "cancel": "Abbrechen"
    },
    "categories": {
      "work": "Rechnungsadresse",
      "home": "Kontaktdaten Vertragspartner",
      "shared": "Persönliche Daten"
    },
    "placeholders": {
      "firstName": "Vornamen eingeben",
      "lastName": "Nachnamen eingeben",
      "nameSuffix": "Namenszusatz eingeben",
      "defaultMessage": "Hallo! Hier schicke ich dir meine Kontaktdaten. Würdest du mir auch deine freigeben? Es freut mich, wenn wir in Kontakt bleiben!",
      "defaultMessage-alt": "Nachfolgend finden Sie meine Kontaktdaten.",
      "email": "E-Mail eingeben",
      "DD": "TT",
      "MM": "MM",
      "YYYY": "JJJJ",
      "link": "Link",
      "phone": "Telefonnummer eingeben",
      "mobile": "Handynummer eingeben",
      "fax": "Faxnummer eingeben",
      "address": "Adresse eingeben",
      "house": "Hausnummer eingeben",
      "addition": "Ortsteil eingeben",
      "country": "Land eingeben",
      "city": "Stadt eingeben",
      "postcode": "Postleitzahl eingeben",
      "street": "Straße eingeben",
      "extra3": {
        "landline": "Empfängerangabe",
        "mobile": "Adresszusatz"
      },
      "companyName": "Unternehmen eingeben",
      "jobTitle": "Position eingeben",
      "position-alt": "Position",
      "salutation-alt": "Anrede",
      "title": "Titel",
      "website-alt": "Webseite",
      "company-alt": "Unternehmen"
    },
    "itemMenu": {
      "edit": "Bearbeiten",
      "remove": "Löschen"
    },
    "buttons": {
      "send": "Senden",
      "next": "Weiter"
    },
    "info": {
      "legal": "<p>Datenschutz liegt uns am Herzen, daher kontrollieren Sie diesen selbst in den Einstellungen.</p>"
    }
  },
  "preference": {
    "title": "Kommunikation",
    "messages": {
      "basic": "Welche Informationen möchten Sie zukünftig von uns erhalten?",
      "scrolled": "Vielen Dank, dass Sie die Daten bestätigt haben. Teilen Sie uns gerne noch mit, wie wir Sie zukünftig kontaktieren dürfen."
    }
  },
  "complete": {
    "sharingTitle": "Alles erledigt",
    "thanksForSharing": "Danke, dass du uns deine Daten mitgeteilt hast.",
    "thanksForSharing-alt": "Danke, dass Sie uns Ihre Daten mitgeteilt haben.",
    "ifYouWish": "Wenn du möchtest, dass meine Kontaktdaten in Zukunft automatisch aktualisiert werden (z. B. für den Fall, dass sich meine Adresse ändert), lad’ dir die Complero-App herunter.",
    "ifYouWish-alt": "Wenn Sie möchten, dass meine Kontaktdaten in Zukunft automatisch aktualisiert werden (z. B. falls sich meine Adresse ändert), laden Sie sich doch die Complero-App herunter.",
    "footerMessage": "Complero speichert all deine Kontakte an einem Ort - und hält sie auf dem neuesten Stand. Sollte ein Freund eine neue Telefonnummer haben, ist sie automatisch in deiner Complero-App aktuell.",
    "footerMessage-alt": "Complero speichert all Ihre Kontakte an einem Ort - und hält sie auf dem neuesten Stand. Sollte ein Kontakt eine neue Telefonnummer haben, ist sie automatisch in Ihrer Complero-App aktuell.",
    "buttons": {
      "vcard": "Hier die Infos von {{firstName}} als vCard herunterladen."
    }
  },
  "account": {
    "manage": {
      "title": "Meine Accounts und Daten verwalten",
      "info": "Entscheiden Sie, wer Zugriff auf welche Daten hat",
      "selectLabel": "Firma auswählen",
      "selectPlaceholder": "Wählen Sie eine Firma",
      "navigation": {
        "toDelete": {
          "title": "Gesamten Account für die folgende Firma löschen",
          "subtitle": "So hat diese Firma keine der freigegebenen Daten mehr von Ihnen."
        },
        "toDownload": {
          "title": "Daten herunterladen",
          "subtitle": "Alle freigegebenen Daten herunterladen"
        }
      }
    },
    "delete": {
      "title": "Unwiderrufliche Löschung",
      "questionForReason": "Warum möchten Sie uns verlassen?",
      "options": {
        "1": "Ich bekomme zu viele Mails",
        "2": "Inhalte sind für mich irrelevant",
        "3": "Ich arbeite mittlerweile in einem anderen Bereich",
        "4": "Sonstiges",
        "5": "",
        "6": "",
        "7": "",
        "8": "",
        "9": "",
        "10": ""
      },
      "warning": "Durch das Löschen werden all Ihre Inhalte und Daten unwiderruflich gelöscht.",
      "agreeCheckbox": "Ich akzeptiere und möchte dennoch fortfahren",
      "btn": "Meine Daten unwiderruflich löschen"
    },
    "download": {
      "title": "Daten herunterladen",
      "btn": "Als CSV-Datei herunterladen"
    }
  },
  "step": {
    "contact": "Kontakt",
    "subscribe": "Abonnieren",
    "done": "Check"
  },
  "publicProfile": {
    "editorTitle": "E-Mail schreiben",
    "messagePlaceholder": "Schreibe hier deine Nachricht",
    "messagePlaceholder-alt": "Schreiben Sie hier eine Nachricht",
    "messageSubject": "Von Complero",
    "buttons": {
      "vcard": "Als Kontakt hinzufügen",
      "mail": "{{firstName}} kontaktieren",
      "done": "Check",
      "cancel": "Abbrechen"
    }
  },
  "notFound": {
    "oups": "Oups.",
    "sorry": "Da ist was schief gegangen: Diese Seite existiert nicht."
  },
  "home": {
    "header": "Aktuelle Kontakte auf Knopfdruck",
    "text": "Der Service von Complero ermöglicht es Privatpersonen, Ihre Kundendaten bei Banken, Versicherungsunternehmen und Telekommunikationsanbietern einfach und unkompliziert zu aktualisieren. Sollten sich Änderungen (zb. durch Umzug) ergeben, können diese vom Kunden in wenigen Klicks eingetragen und abgesendet werden. Die Aktualisierung übernehmen die betroffenen Versicherungsunternehmen in Ihrer Datenbank - so werden Ressourcen und Nerven gespart.",
    "text-2": "Veraltete Kontakte und fehlende Nummern gehören somit der Vergangenheit an. Ab sofort hält Complero deine Adressdaten immer aktuell.",
    "text-alt": "Veraltete Kontakte und fehlende Nummern gehören der Vergangenheit an. Ab sofort hält Complero Ihr Adressbuch immer aktuell.",
    "button": "Zur Startseite",
    "buttonExplain": "Klicke hier um zur Startseite zurück zu gelangen"
  },
  "info": {
    "buttons": {
      "imp": "Impressum",
      "data": "Datenschutzerklärung",
      "show": "Impressum",
      "settings": "Einstellungen",
      "hide": "OK"
    }
  },
  "titles": {
    "dr": "Dr.",
    "prof": "Prof.",
    "profDr": "Prof. Dr."
  },
  "salutations": {
    "mr": "Herr",
    "ms": "Frau",
    "mrs": "Frau",
    "spouses": "Eheleute",
    "notSpecified": "Keine Angabe"
  },
  "authentication": {
    "title": "Wir haben Ihnen eine SMS geschickt",
    "description": "Geben Sie den Code ein den Sie per SMS an die folgende Nummer erhalten haben um sich zu authentifizieren:",
    "instructions": "Geben Sie hier den sechsstelligen Code ein:",
    "outdatedPhoneNumber": "Ihre Telefonnummer ist nicht mehr aktuell?",
    "verifyButton": "Überprüfen",
    "resendButton": "Sie können uns natürlich auch gerne jederzeit telefonisch kontaktieren.",
    "learnMoreButton": "Mehr erfahren",
    "serviceProvider": "Dienstleister",
    "confirmationTitle": "SMS-TAN wurde versandt",
    "confirmationDescription": "Sie sollten eine SMS mit einer sechsstelligen TAN-Nummer an ihrem Mobiltelefon mit der Rufnummer {{tanRecipient}} erhalten haben.",
    "timerTitle": "Bitte warten Sie einen Augenblick",
    "timerDescription": "Sie müssen noch {{time}} Sekunden warten bis Sie den nächsten Code anfordern können.",
    "errorTitle": "Der SMS-TAN scheint nicht korrekt zu sein",
    "errorDescription": "Bitte vergewissern Sie sich, dass Sie alle Ziffern der TAN korrekt eingegeben haben und versuchen Sie es dann erneut."
  },
  "validation": {
    "formValidationErrorTitle": "Einige Eingaben scheinen nicht korrekt zu sein.",
    "formValidationErrorMessage": "Bitte überprüfen Sie Ihre Eingaben und senden Sie das Formular erneut ab.",
    "mandatory_fields_missing": "Bitte füllen Sie alle Pflichtfelder aus.",
    "required": "Pflichtfeld",
    "phoneValidation": "Die Telefonnummer scheint nicht korrekt zu sein. Bitte benutzen Sie das internationale Format z.B. +49***********",
    "faxValidation": "Die Faxnummer scheint nicht korrekt zu sein. Bitte benutzen Sie das internationale Format z.B. +49***********",
    "mobileValidation": "Die Mobilfunknummer scheint nicht korrekt zu sein. Bitte benutzen Sie das internationale Format z.B. +49***********",
    "phoneOverLimit": "Zu viele Telefonnummern",
    "mobileOverLimit": "Zu viele Mobilfunknummern",
    "faxOverLimit": "Zu viele Faxnummern",
    "landPhoneValidation": "",
    "emailValidation": "Die E-Mail-Adresse scheint nicht korrekt zu sein",
    "emailImplausible": "Die E-Mail-Adresse scheint nicht korrekt zu sein",
    "emailOverLimit": "Zu viele E-Mail-Adressen",
    "postalCodeValidation": "Die eigegebene Postleitzahl entspricht nicht dem 5-stelligen Standardformat.",
    "notEmptyValidation": "Dieses Feld darf nicht leer sein.",
    "notEmptyValidationMultiple": "Die markierten Felder dürfen nicht leer sein.",
    "houseValidation": "Die eingegebene Hausnummer ist nicht korrekt",
    "email": "Die eingegebene E-Mail-Adresse scheint nicht korrekt zu sein.",
    "first_name": "Der Vorname scheint ungültig zu sein",
    "first_nameOverLimit": "Zu viele Vornamen",
    "last_name": "Der Nachname scheint ungültig zu sein",
    "last_nameOverLimit": "Zu viele Nachnamen",
    "image_url": "Die eingegebene Bild-URL scheint nicht korrekt zu sein.",
    "website": "Die eingegebene Website-URL scheint nicht korrekt zu sein.",
    "street": "Die eingegebene Straße scheint nicht korrekt zu sein.",
    "city": "Die eingegebene Stadt scheint nicht korrekt zu sein.",
    "addressValidation": "Die eingegebene Adresse scheint nicht korrekt zu sein",
    "addressOverLimit": "Zu viele Adressen",
    "postal_code": "Die eingegebene Postleitzahl scheint nicht korrekt zu sein.",
    "salutation": "Bitte wählen Sie eine Anrede aus.",
    "title": "Bitte wählen Sie einen Titel aus.",
    "ZipCode": "Die Postleitzahl scheint nicht korrekt zu sein",
    "Street": "Die eingegebene Straße scheint nicht korrekt zu sein.",
    "City": "Die eingegebene Stadt scheint nicht korrekt zu sein.",
    "Country": "Bitte wählen Sie ein Land aus",
    "Birthday": "Das Geburtsdatum scheint nicht korrekt zu sein",
    "birthdayOverLimit": "Zu viele Geburtstage",
    "profilePicOverLimit": "Zu viele Profilbilder",
    "titleOverLimit": "Zu viele Titel",
    "salutationOverLimit": "Zu viele Titel"
  },
  "birthday": {
    "title": "Verifizierung",
    "description": "Bitte geben Sie Ihr Geburtsdatum ein, um Ihre Identität zu bestätigen.",
    "helpText": "Wie lautet Ihr Geburtsdatum?",
    "verifyButton": "Überprüfen",
    "dayFormat": "DD",
    "monthFormat": "MM",
    "yearFormat": "YYYY",
    "day": "Tag",
    "month": "Monat",
    "year": "Jahr",
    "verificationErrorTitle": "Das Geburtsdatum scheint nicht korrekt zu sein",
    "verificationErrorDescription": "Bitte vergewissern Sie sich, dass Sie alle Ziffern des Geburtsdatums korrekt eingegeben haben und versuchen Sie es dann erneut. Sie können uns alternativ auch unter folgender Nummer telefonisch erreichen:",
    "inputErrorTitle": "Das Geburtsdatum scheint nicht korrekt zu sein",
    "inputErrorDescription": "Das eingegebene Geburtsdatum entspricht nicht dem erwarteten Format. Bitte vergewissern Sie sich, dass Sie alle Ziffern des Geburtsdatums korrekt eingegeben haben und versuchen Sie es dann erneut.",
    "inputErrorContactUs": "Sie können uns alternativ auch unter folgender Nummer telefonisch erreichen:",
    "tooManyRequestErrorTitle": "Das Geburtsdatum scheint nicht korrekt zu sein",
    "tooManyRequestErrorDescription": "Das eingegebene Geburtsdatum entspricht nicht dem erwarteten. Bitte versuchen Sie es in 30 Minuten erneut. Sind Sie überzeugt Ihr Geburtsdatum ist korrekt? Dann haben wir möglicherweise ein falsches Datum erhalten. Bitte wenden Sie sich in diesem Fall direkt an",
    "tooManyRequestLink": ""
  },
  "zipCode": {
    "title": "Verifizierung",
    "description": "Bitte verifizieren Sie Ihre Postleitzahl um fortzusetzen",
    "helpText": "Postleitzahl",
    "verifyButton": "Bestätigen",
    "dayFormat": "DD",
    "monthFormat": "MM",
    "yearFormat": "YYYY",
    "day": "Tag",
    "month": "Monat",
    "year": "Jahr",
    "verificationErrorTitle": "Das Geburtsdatum scheint nicht korrekt zu sein",
    "verificationErrorDescription": "Bitte vergewissern Sie sich, dass Sie alle Ziffern des Geburtsdatums korrekt eingegeben haben und versuchen Sie es dann erneut. Sie können uns alternativ auch unter folgender Nummer telefonisch erreichen:",
    "inputErrorTitle": "Die Postleitzahl scheint nicht korrekt zu sein",
    "inputErrorDescription": "Die eingegebene Postleitzahl scheint nicht korrekt zu sein. Bitte vergewissern Sie sich, dass Sie alle Zeichen korrekt eingegeben haben und versuchen Sie es dann erneut.",
    "inputErrorContactUs": "Sie können uns alternativ auch unter folgender Nummer telefonisch erreichen:",
    "tooManyRequestErrorTitle": "Das Geburtsdatum scheint nicht korrekt zu sein",
    "tooManyRequestErrorDescription": "Das eingegebene Geburtsdatum entspricht nicht dem erwarteten. Bitte versuchen Sie es in 30 Minuten erneut. Sind Sie überzeugt Ihr Geburtsdatum ist korrekt? Dann haben wir möglicherweise ein falsches Datum erhalten. Bitte wenden Sie sich in diesem Fall direkt an",
    "tooManyRequestLink": ""
  },
  "error": {
    "title": "Wir können Ihre Anfrage derzeit nicht verarbeiten.",
    "descriptionLine1": "Leider haben wir momentan technische Probleme mit unserem Service. Unser Team arbeitet mit Hochdruck daran, die Störung zu beheben.",
    "descriptionLine2": "Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.",
    "buttonHelpText": "Wir verbessern stetig den Complero-Service und würden uns freuen, Ihre Meinung dazu zu erfahren!",
    "buttonTitle": "Termin vereinbaren"
  },
  "expired": {
    "title": "Ups, der angeforderte Link ist nicht mehr gültig.",
    "descriptionLine1": "Möglicherweise ist der Link abgelaufen, da er nicht innerhalb der vorgesehenen Frist genutzt wurde. Aus Sicherheitsgründen werden unsere Links regelmäßig deaktiviert, um Ihre Daten zu schützen.",
    "descriptionLine2": "Für die Aktualisierung Ihrer Kontaktdaten bitten wir Sie, direkt mit Ihrem Vertragspartner Kontakt aufzunehmen.",
    "buttonHelpText": "Wir verbessern stetig den Complero-Service und würden uns freuen, Ihre Meinung dazu zu erfahren!",
    "buttonTitle": "Termin vereinbaren"
  },
  "reminder": {
    "title": "Mobilnummer angeben",
    "line1": "Damit wir Sie telefonish erreichen können, geben Sie bitte zusätzlich Ihre Mobilnummer an.",
    "line2": "Wir werden Ihre Nummer nicht für Werbezwecke nutzen und Sie lediglich im Bedarfsfall kontaktieren.",
    "ignore": "Ignorieren",
    "button": "Mobilnummer hinzufügen"
  },
  "confirmation": {
    "thanks": "Vielen Dank.",
    "success": "Ihre Daten wurden erfolgreich übermittelt.",
    "almost": "Fast geschafft.",
    "emailSent": "Zur Bestätigung Ihrer neuen E-Mail- Adresse erhalten Sie eine seperate Nachricht an Ihre neue E-Mail-Adresse.",
    "feedback-title": "Zeit für Feedback?",
    "feedback-body": "Wir freuen uns über eine kurze Rückmeldung zu Ihrer Erfahrung!",
    "feedback-link": "Ihre Meinung ist uns wichtig",
    "disclaimer-pre": "Dieser Service wurde Ihnen von",
    "disclaimer-post": "angeboten.",
    "disclaimer-link": "Impressum"
  },
  "buttons": {
    "close": "Schliessen"
  },
  "custom": {
    "email": "",
    "phone": "",
    "link": "",
    "birthday": "",
    "country": "",
    "companyName": "",
    "jobTitle": ""
  },
  "topbar": {
    "deliveryNote": "Zustellhinweis geben",
    "contract": "Vertragsnummer"
  },
  "global": {
    "loading": "Am Laden",
    "shortReceiveTime": "Laden... bitte warte einen Augenblick",
    "longReceiveTime": "Das Laden dauert länger als gewöhnlich. Bitte versuche es später erneut",
    "shortSubmitTime": "Speichern... bitte warte einen Augenblick",
    "longSubmitTime": "Das Speichern dauert länger als gewöhnlich. Bitte warte noch etwas oder versuche es später ernaut",
    "internalServerError": "Oups, da ist was schief gegangen",
    "internalServerErrorMessage": "Bitte versuchen Sie es später erneut"
  }
}
