import { FieldConfigsFactory, IFieldRemoteConfig } from '@c-fields/common';

import { PageType } from '../../../libs/common/enums/page.type';
import { FeatureType } from '../../../libs/common/enums/feature.type';
import { FieldType } from '../../../libs/common/enums/field.type';
import { SetupInterface } from '../../../libs/common/interfaces/setup.interface';
import { VerificationType } from '../../../libs/common/enums/verification-type.enum';

import { flowACompactSetup } from './setup-bases/flow_a_compact.setup';
import { flow2FAACompactSetup } from './setup-bases/flow_2fa_a_compact.setup';
import { flowASharedSectionSetup } from './setup-bases/flow_a_shared_section.setup';
import { flowDobSharedSetup } from './setup-bases/flow_dob_shared.setup';
import { flowBSplitSetup } from './setup-bases/flow_b_split.setup';
import { flowTSMSSetup } from './setup-bases/flow_t_sms.setup';
import { flowTEmailSetup } from './setup-bases/flow_t_email.setup';
import { flowAllianzSetup } from './setup-bases/flow-allianz.setup';
import { flowDefaultSetup } from './setup-bases/flow-default.setup';
import { flowProvinzialSetup } from './setup-bases/flow-provinzial.setup';


export class SetupAdapter {

  public static makeSetup(meta: {
    flow: string,
    accepted_form_fields: string[],
    form_field_configs: IFieldRemoteConfig[],
    verification_type: VerificationType,
    features: {
      include_employee: boolean,
      show_contract_nr_topbar: boolean,
    }
  }): SetupInterface {

    const setupBase: SetupInterface = SetupAdapter.makeSetupBase(meta);

    setupBase.verificationType = meta?.verification_type;
    setupBase.includeEmployee = meta?.features?.include_employee;
    setupBase.showTopbar = meta?.features?.show_contract_nr_topbar;
    //FIXME move this to more suitable & testable place
    setupBase.showConsents = meta.accepted_form_fields.indexOf('advertisement_consent') !== -1
      || meta.accepted_form_fields.indexOf('advertisement_consent_ro') !== -1;

    if (meta.form_field_configs) {
      setupBase.fieldConfigs = FieldConfigsFactory
        .buildFromConfigObjects(meta.form_field_configs);
    } else if (meta.accepted_form_fields) {
      setupBase.fieldConfigs = FieldConfigsFactory
        .buildFromConfigStrings(meta.accepted_form_fields);
    }

    this.setupFields(setupBase, meta.accepted_form_fields);

    /* ----------------------------------------
     * ❓ Enable options depends on presence of some fields
     * -------------------------------------- */
    const showWorkFolder =
      setupBase.fields[PageType.Answering][FieldType.WorkPhone]
      || setupBase.fields[PageType.Answering][FieldType.WorkMobile]
      || setupBase.fields[PageType.Answering][FieldType.WorkLandPhone]
      || setupBase.fields[PageType.Answering][FieldType.WorkEmail]
      || setupBase.fields[PageType.Answering][FieldType.WorkAddress]
      || setupBase.fields[PageType.Answering][FieldType.WorkFullAddress]
      || setupBase.fields[PageType.Answering][FieldType.WorkUrl];

    const showHomeFolder =
      setupBase.fields[PageType.Answering][FieldType.HomePhone]
      || setupBase.fields[PageType.Answering][FieldType.HomeLandPhone]
      || setupBase.fields[PageType.Answering][FieldType.HomeMobile]
      || setupBase.fields[PageType.Answering][FieldType.HomeEmail]
      || setupBase.fields[PageType.Answering][FieldType.HomeAddress]
      || setupBase.fields[PageType.Answering][FieldType.HomeFullAddress]
      || setupBase.fields[PageType.Answering][FieldType.HomeUrl]
      || setupBase.fields[PageType.Answering][FieldType.Birthday];

    setupBase.features[PageType.Answering][FeatureType.WorkFolder] = showWorkFolder;
    setupBase.features[PageType.Answering][FeatureType.HomeFolder] = showHomeFolder;

    setupBase.features[PageType.Answering][FeatureType.HomeFolderOpen] = true;
    setupBase.features[PageType.Answering][FeatureType.WorkFolderOpen] = !showHomeFolder;

    return setupBase;
  }

  private static makeSetupBase(meta: { flow: string, accepted_form_fields: string[], verification_type: VerificationType }): SetupInterface {
    switch (meta.flow) {
      case 'flow_a_compact':
        return flowACompactSetup;
      case 'flow_2fa_a_compact':
        // Flow name is important so we know whether we need to check for the presence of a 2FA code
        return flow2FAACompactSetup;
      case 'flow_a_shared_section':
        // Flow name is important so we know whether we need to check for the presence of a 2FA code
        return flowASharedSectionSetup;
      case 'flow_dob_shared':
        // Flow name is important so we know whether we need to check for the presence of a DOB code
        return flowDobSharedSetup;
      case 'flow_b_split':
        return flowBSplitSetup;
      case 'flow_t_email':
        return flowTEmailSetup;
      case 'flow_t_sms':
        return flowTSMSSetup;
      case 'flow_allianz':
        return flowAllianzSetup;
      case 'flow_provinzial':
        return flowProvinzialSetup;
      case 'flow_default':
        return flowDefaultSetup;
    }
  }

  private static setupFields(
    setupBase: SetupInterface,
    fields: string[],
    readOnly?: boolean,
    required?: boolean,
    limit?: number,
  ) {
    if (!fields) {
      return;
    }

    fields.forEach((fieldName) => {
      switch (fieldName) {
        case 'title':
          setupBase.fields[PageType.Answering][FieldType.Title] = true;
          this.processFieldControlParams(setupBase, FieldType.Title, readOnly, required, limit);
          break;
        case 'salutation':
          setupBase.fields[PageType.Answering][FieldType.Salutation] = true;
          this.processFieldControlParams(setupBase, FieldType.Salutation, readOnly, required, limit);
          break;
        case 'first_name':
          setupBase.fields[PageType.Answering][FieldType.FirstName] = true;
          this.processFieldControlParams(setupBase, FieldType.FirstName, readOnly, required, limit);
          break;
        case 'last_name':
          setupBase.fields[PageType.Answering][FieldType.LastName] = true;
          this.processFieldControlParams(setupBase, FieldType.LastName, readOnly, required, limit);
          break;
        case 'name_suffix':
          setupBase.fields[PageType.Answering][FieldType.NameSuffix] = true;
          this.processFieldControlParams(setupBase, FieldType.NameSuffix, readOnly, required, limit);
          break;
        case 'position':
          setupBase.fields[PageType.Answering][FieldType.Position] = true;
          this.processFieldControlParams(setupBase, FieldType.Position, readOnly, required, limit);
          break;
        case 'company':
          setupBase.fields[PageType.Answering][FieldType.Company] = true;
          this.processFieldControlParams(setupBase, FieldType.Company, readOnly, required, limit);
          break;
        case 'website':
          setupBase.fields[PageType.Answering][FieldType.Website] = true;
          this.processFieldControlParams(setupBase, FieldType.Website, readOnly, required, limit);
          break;
        case 'private_mobile':
          setupBase.fields[PageType.Answering][FieldType.HomeMobile] = true;
          this.processFieldControlParams(setupBase, FieldType.HomeMobile, readOnly, required, limit);
          break;
        case 'private_phone':
          setupBase.fields[PageType.Answering][FieldType.HomeLandPhone] = true;
          this.processFieldControlParams(setupBase, FieldType.HomeLandPhone, readOnly, required, limit);
          break;
        // case 'private_phones': // deprecated
        //   setupBase.fields[PageType.Answering][FieldType.HomePhone] = true;
        //   this.processFieldControlParams(setupBase, FieldType.HomePhone, readOnly, required, limit);
        //   break;
        case 'private_faxes':
          setupBase.fields[PageType.Answering][FieldType.HomeFax] = true;
          this.processFieldControlParams(setupBase, FieldType.HomeFax, readOnly, required, limit);
          break;
        case 'private_emails':
          setupBase.fields[PageType.Answering][FieldType.HomeEmail] = true;
          this.processFieldControlParams(setupBase, FieldType.HomeEmail, readOnly, required, limit);
          break;
        case 'private_addresses':
          setupBase.fields[PageType.Answering][FieldType.HomeAddress] = true;
          this.processFieldControlParams(setupBase, FieldType.HomeAddress, readOnly, required, limit);
          break;
        case 'private_addresses_full':
          setupBase.fields[PageType.Answering][FieldType.HomeFullAddress] = true;
          this.processFieldControlParams(setupBase, FieldType.HomeFullAddress, readOnly, required, limit);
          break;
        case 'private_urls':
          setupBase.fields[PageType.Answering][FieldType.HomeUrl] = true;
          this.processFieldControlParams(setupBase, FieldType.HomeUrl, readOnly, required, limit);
          break;
        case 'private_birthday':
          setupBase.fields[PageType.Answering][FieldType.Birthday] = true;
          this.processFieldControlParams(setupBase, FieldType.Birthday, readOnly, required, limit);
          break;
        case 'work_mobile':
          setupBase.fields[PageType.Answering][FieldType.WorkMobile] = true;
          this.processFieldControlParams(setupBase, FieldType.WorkMobile, readOnly, required, limit);
          break;
        case 'work_phone':
          setupBase.fields[PageType.Answering][FieldType.WorkLandPhone] = true;
          this.processFieldControlParams(setupBase, FieldType.WorkLandPhone, readOnly, required, limit);
          break;
        // case 'work_phones': // deprecated
        //   setupBase.fields[PageType.Answering][FieldType.WorkPhone] = true;
        //   this.processFieldControlParams(setupBase, FieldType.WorkPhone, readOnly, required, limit);
        //   break;
        case 'work_faxes':
          setupBase.fields[PageType.Answering][FieldType.WorkFax] = true;
          this.processFieldControlParams(setupBase, FieldType.WorkFax, readOnly, required, limit);
          break;
        case 'work_emails':
          setupBase.fields[PageType.Answering][FieldType.WorkEmail] = true;
          this.processFieldControlParams(setupBase, FieldType.WorkEmail, readOnly, required, limit);
          break;
        case 'work_addresses':
          setupBase.fields[PageType.Answering][FieldType.WorkAddress] = true;
          this.processFieldControlParams(setupBase, FieldType.WorkAddress, readOnly, required, limit);
          break;
        case 'work_addresses_full':
          setupBase.fields[PageType.Answering][FieldType.WorkFullAddress] = true;
          this.processFieldControlParams(setupBase, FieldType.WorkFullAddress, readOnly, required, limit);
          break;
        case 'work_urls':
          setupBase.fields[PageType.Answering][FieldType.WorkUrl] = true;
          this.processFieldControlParams(setupBase, FieldType.WorkUrl, readOnly, required, limit);
          break;
        case 'profile_picture':
          setupBase.fields[PageType.Answering][FieldType.Avatar] = true;
          break;
        case 'advertisement_consent':
          setupBase.features[PageType.Answering][FeatureType.Preferences] = true;
          setupBase.features[PageType.Answering][FeatureType.PreferenceMessage] = true;
          break;
        case 'settings':
          setupBase.features[PageType.Answering][FeatureType.Settings] = true;
          break;
        case 'vcard_download':
          setupBase.features[PageType.Sharing][FeatureType.SharingVcard] = true;
          break;
      }

      // Read only
      if (fieldName.includes('_ro')) {
        this.setupFields(setupBase, [fieldName.replace('_ro', '')], true);
      }

      // Required
      // This is actually not being served from API
      if (fieldName.includes('_required')) {
        this.setupFields(setupBase, [fieldName.replace('_required', '')], readOnly, true);
      }

      // Limited
      if (fieldName.includes('_limited_')) {
        const [fieldBaseName, limitAmount] = fieldName.split('_limited_');
        this.setupFields(setupBase, [fieldBaseName], readOnly, required, Number(limitAmount));
      }
    });
  }

  private static processFieldControlParams(
    setupBase: SetupInterface,
    fieldType: FieldType,
    readOnly: boolean = null,
    required: boolean = null,
    limit: number = null,
  ) {
    this.addReadOnly(setupBase, fieldType, readOnly);
    this.addRequired(setupBase, fieldType, required);
    this.addLimit(setupBase, fieldType, limit);
  }

  private static addReadOnly(setupBase: SetupInterface, fieldType: FieldType, readOnly?: boolean) {
    if (readOnly) {
      setupBase.readOnly[PageType.Answering][fieldType] = readOnly;
    }
  }

  private static addRequired(setupBase: SetupInterface, fieldType: FieldType, required?: boolean) {
    if (required) {
      setupBase.required[PageType.Answering][fieldType] = required;
    }
  }

  private static addLimit(setupBase: SetupInterface, fieldType: FieldType, limit?: number) {
    if (limit) {
      setupBase.limits[PageType.Answering][fieldType] = limit;
    }
  }

}
